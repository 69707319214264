<template>
	<div class="total drag" v-draw id="CfbParameter8">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="title">{{projectData.project}}_参数08_1</div>
				<div class="icon" @click="closeCompon"></div>
			</div>
		</div>
		<!-- 标题 -->
		<div class="main">
			<div class="main_title">
				冷渣机优化控制模型
			</div>
		</div>
		<!-- 主体内容 -->
		<div class="context flex">
			<div class="one">
				<div class="shadow shadow1 flex">
					<div class="column1">I01-SP</div>
					<div class="column2" @click="toIpt(infoList.MCSCZKZ.LCSP_B1,'I01-SP','LCSP_B1','MCSCZKZ')">
						{{infoList.MCSCZKZ.LCSP_B1}}
					</div>
				</div>
				<div class="shadow shadow2">
					<div class="flex">
						<div class="column1">I02-TC</div>
						<div class="column2" @click="toIpt(infoList.MCSYCL.TC002,'I02-TC','TC002','MCSYCL')">
							{{infoList.MCSYCL.TC002}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">I02-YZ</div>
						<div class="column2">0.00</div>
					</div>
					<div class="float1 flex">
						<div class="button1">
							<div class="button1_word">I02-CYCL1</div>
						</div>
						<div class="column3" @click="toCompon(2,'DPT101D_B','MCSSOURCE','DPT101D_BVV1','左侧料层差压')"
							@dblclick="Cclick(infoList.MCSSOURCE.DPT101D_B,'DPT101D_B','MCSSOURCE','DPT101D_BVV1')">
							{{infoList.MCSSOURCE.DPT101D_B}}
						</div>
					</div>
					<div class="flex">
						<div class="button2">
							<div class="button2_word">I02-CYCL2</div>
						</div>
						<div class="column3" @click="toCompon(2,'DPT102D_B','MCSSOURCE','DPT102D_BVV1','右侧料层差压')"
							@dblclick="Cclick(infoList.MCSSOURCE.DPT102D_B,'DPT102D_B','MCSSOURCE','DPT102D_BVV1')">
							{{infoList.MCSSOURCE.DPT102D_B}}
						</div>
					</div>
				</div>
			</div>
			<div class="two flex">
				<div class="shadow shadow1 float1">
					<div class="shadow_words">料层预测模型</div>
					<div class="flex">
						<div class="column1">I02-PV</div>
						<div class="column3" @click="toCompon(2,'SJLCCY','MCSYCL','SJLCCYVV1')"
							@dblclick="Cclick(infoList.MCSYCL.SJLCCY,'SJLCCY','MCSYCL','SJLCCYVV1')">
							{{infoList.MCSYCL.SJLCCY}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">I02-CYZQ</div>
						<div class="column2" @click="toIpt(infoList.MCSBY.BYMNL74,'I02-CYZQ','BYMNL74','MCSBY')">
							{{infoList.MCSBY.BYMNL74}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">I02-LBSJ</div>
						<div class="column2" @click="toIpt(infoList.MCSBY.BYMNL75,'I02-LBSJ','BYMNL75','MCSBY')">
							{{infoList.MCSBY.BYMNL75}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">I02-YCSJ</div>
						<div class="column2" @click="toIpt(infoList.MCSBY.BYMNL76,'I02-YCSJ','BYMNL76','MCSBY')">
							{{infoList.MCSBY.BYMNL76}}
						</div>
					</div>
				</div>
				<div class="shadow shadow2 float2 flex">
					<div class="column1">I01-PV1</div>
					<div class="column3" @click="toCompon(2,'PV','MCSXK__p__XK0C','XK0C_PVVV1','XK0C测量值')"
						@dblclick="Cclick(infoList.MCSXK__p__XK0C.PV,'PV','MCSXK__p__XK0C','XK0C_PVVV1')">
						{{infoList.MCSXK__p__XK0C.PV}}
					</div>
				</div>
				<div class="buttonx float3" :style="{
						background:infoList.MCSXK__p__XK0C && !infoList.MCSXK__p__XK0C.RM
						? '#2AFC30'
						: 'red',
						}" @click="infoList.MCSXK__p__XK0C
						? toDetail(1, 'XK0C', 'MCSXK__p__XK0C', '', '料层调节'): ''">
					X
				</div>
				<div>
					<div class="shadow shadow2 float4 flex">
						<div class="column1">I01-AV</div>
						<div class="column3" @click="toCompon(2,'AV','MCSXK__p__XK0C','XK0C_AVVV1','XK0C总输出')"
							@dblclick="Cclick(infoList.MCSXK__p__XK0C.AV,'AV','MCSXK__p__XK0C','XK0C_AVVV1')">
							{{infoList.MCSXK__p__XK0C.AV}}
						</div>
					</div>
					<div class="shadow shadow3 float5">
						<div class="shadow_words">渣温水温条件</div>
						<div class="flex">
							<div class="column1">I04-SWH</div>
							<div class="column2" @click="toIpt(infoList.MCSYCL.CZSWH,'I04-SWH','CZSWH','MCSYCL')">
								{{infoList.MCSYCL.CZSWH}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">I04-PZH</div>
							<div class="column2" @click="toIpt(infoList.MCSYCL.CZPZH,'I04-PZH','CZPZH','MCSYCL')">
								{{infoList.MCSYCL.CZPZH}}
							</div>
						</div>
					</div>
				</div>
				<div class="pz">
					<div class="pz1">PZ1</div>
					<div class="pz2">PZ2</div>
					<div class="pz3">PZ3</div>
					<div class="pz4">PZ4</div>
				</div>
				<div class="buttonx float6" :style="{
					background:
					infoList.MCSMAN__p__MAN13.RM == 1 || infoList.MCSMAN__p__MAN14.RM == 1 || infoList.MCSMAN__p__MAN28.RM == 1 || infoList.MCSMAN__p__MAN29.RM == 1 ? '#2AFC30' : 'red'
					}" @click=" toCompon(0,'CFB_MANCZ',4)">A</div>
			</div>
			<div class="three">
				<div class="shadow shadow1 float1">
					<div class="flex">
						<div class="column1">I06-A01</div>
						<div class="column3" @click="toCompon(2,'CZAO1_B','MCSAO','CZAO1_BVV1','1#除渣机调节输出')"
							@dblclick="Cclick(infoList.MCSAO.CZAO1_B,'CZAO1_B','MCSAO','CZAO1_BVV1')">
							{{infoList.MCSAO.CZAO1_B}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">I06-A0-HL</div>
						<div class="column2"
							@click="toIpt(infoList.MCSMAN__p__MAN13.OUTT,'I06-A0-HL','OUTT','MCSMAN__p__MAN13')">
							{{infoList.MCSMAN__p__MAN13.OUTT}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">I06-A0-LL</div>
						<div class="column2"
							@click="toIpt(infoList.MCSMAN__p__MAN13.OUTB,'I06-A0-LL','OUTB','MCSMAN__p__MAN13')">
							{{infoList.MCSMAN__p__MAN13.OUTB}}
						</div>
					</div>
				</div>
				<div class="shadow shadow1 float2">
					<div class="flex">
						<div class="column1">I06-A02</div>
						<div class="column3" @click="toCompon(2,'CZAO2_B','MCSAO','CZAO2_BVV1','2#除渣机调节输出')"
							@dblclick="Cclick(infoList.MCSAO.CZAO2_B,'CZAO2_B','MCSAO','CZAO2_BVV1')">
							{{infoList.MCSAO.CZAO2_B}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">I06-A0-HL</div>
						<div class="column2"
							@click="toIpt(infoList.MCSMAN__p__MAN14.OUTT,'I06-A0-HL','OUTT','MCSMAN__p__MAN14')">
							{{infoList.MCSMAN__p__MAN14.OUTT}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">I06-A0-LL</div>
						<div class="column2"
							@click="toIpt(infoList.MCSMAN__p__MAN14.OUTB,'I06-A0-LL','OUTB','MCSMAN__p__MAN14')">
							{{infoList.MCSMAN__p__MAN14.OUTB}}
						</div>
					</div>
				</div>
				<div class="shadow shadow1 float3">
					<div class="flex">
						<div class="column1">I06-A03</div>
						<div class="column3" @click="toCompon(2,'CZAO3_B','MCSAO','CZAO3_BVV1','1#除渣机调节输出')"
							@dblclick="Cclick(infoList.MCSAO.CZAO3_B,'CZAO3_B','MCSAO','CZAO3_BVV1')">
							{{infoList.MCSAO.CZAO3_B}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">I06-A0-HL</div>
						<div class="column2"
							@click="toIpt(infoList.MCSMAN__p__MAN28.OUTT,'I06-A0-HL','OUTT','MCSMAN__p__MAN28')">
							{{infoList.MCSMAN__p__MAN28.OUTT}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">I06-A0-LL</div>
						<div class="column2"
							@click="toIpt(infoList.MCSMAN__p__MAN28.OUTB,'I06-A0-LL','OUTB','MCSMAN__p__MAN28')">
							{{infoList.MCSMAN__p__MAN28.OUTB}}
						</div>
					</div>
				</div>
				<div class="shadow shadow1 float4">
					<div class="flex">
						<div class="column1">I06-A04</div>
						<div class="column3" @click="toCompon(2,'CZAO4_B','MCSAO','CZAO4_BVV1','2#除渣机调节输出')"
							@dblclick="Cclick(infoList.MCSAO.CZAO4_B,'CZAO4_B','MCSAO','CZAO4_BVV1')">
							{{infoList.MCSAO.CZAO4_B}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">I06-A0-HL</div>
						<div class="column2"
							@click="toIpt(infoList.MCSMAN__p__MAN29.OUTT,'I06-A0-HL','OUTT','MCSMAN__p__MAN29')">
							{{infoList.MCSMAN__p__MAN29.OUTT}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">I06-A0-LL</div>
						<div class="column2"
							@click="toIpt(infoList.MCSMAN__p__MAN29.OUTB,'I06-A0-LL','OUTB','MCSMAN__p__MAN29')">
							{{infoList.MCSMAN__p__MAN29.OUTB}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<manyManual v-if="manyManual" :titname="Manualtitname" :numM="Manualnode" @sendStatus="isClose"
			:infoList="infoList"></manyManual>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import manyManual from "@/components/manyManual.vue"; //手操器组件
	export default {
		name: "CfbParameter3",
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		components: {
			inputVal,
			Historical,
			manyManual
		},
		data: () => {
			return {
				chName: '',
				manyManual: false,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
					console.log(this.infoList)
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))

		},
		methods: {
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'Cfbkfirstcontol'
							break
						case 3:
							pathname = 'CfbRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'Parameter08', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
					case 'manyManual':
						return this.manyManual = val1
				}
			},
			toIpt(data, name, historyname, node) {

				this.$refs.inputVal.open(data, name, historyname, node)
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.manyManual = true, this.Manualtitname = name, this.Manualnode = name2
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>

<style lang="scss" scoped>
	#CfbParameter8 {
		width: 53vw;
		height: 67vh;
		background-image: url("~@/assets/images/CfbBoiler/para031_bg.png");
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -33.5vh 0 0 -26.5vw;
		z-index: 999;

		.title-hang {
			width: 53vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			margin-left: 15vw;
		}

		.title {
			width: 19vw;
			height: 4vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top: 1vh;
			text-align: center;
			margin-left: 1.5vw;
		}

		.icon {
			width: 1.8vw;
			height: 3.2vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 15vw;
			margin-top: 1.8vh;
		}

		.main {
			width: 53vw;
			height: 2vh;
			margin-top: 3vh;

			.main_title {
				font-family: PingFang-SC-Regular;
				font-size: 3vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}

		.context {
			width: 49vw;
			height: 31vh;
			margin-top: 4vh;
			margin-left: 2vw;
			font-family: PingFang-SC-Regular;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;

			.shadow {
				background-color: #001b2860;
				box-shadow: 0vw 0vw 0vw 0vw #15384c;
				border-radius: 1px;
				border: solid 1px #236f8d;
				font-size: 1.2vh;

				.column1 {
					width: 4.5vw;
					color: #8aeaff;
					margin-left: 0.3vw;
				}

				.column2 {
					width: 2vw;
					color: #00ffb4;
					text-align: right;
				}

				.column3 {
					width: 2vw;
					color: #00e4ff;
					text-align: right;
				}
			}

			.buttonx {
				width: 1.3vw;
				height: 2.2vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 2vh;
				background-color: #00e4ff;
				color: white;
			}

			.one {
				.shadow1 {
					width: 8vw;
					height: 2.7vh;
					line-height: 2.8vh;
					margin-top: 6vh;
				}

				.shadow2 {
					margin-top: 8vh;
					width: 8vw;
					height: 12vh;
				}

				.float1 {
					margin-top: 2vh;
				}

				.button1 {
					width: 4.5vw;
					height: 2vh;
					background-color: #22fff7;
					border-radius: 0vh;
					font-family: PingFang-SC-Regular;
					font-size: 1vh;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2vh;
					letter-spacing: 0vh;
					margin-left: 0.2vw;

					.button1_word {
						text-align: center;
						color: #0a4c62;
					}
				}

				.button2 {
					width: 4.5vw;
					height: 2vh;
					background-color: #26a2d7;
					border-radius: 0vh;
					font-family: PingFang-SC-Regular;
					font-size: 1vh;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2vh;
					letter-spacing: 0vh;
					margin-left: 0.2vw;

					.button2_word {
						text-align: center;
						color: #d5fffe;
					}
				}
			}

			.two {
				width: 35vw;
				height: 26vh;
				background-image: url("~@/assets/images/CfbBoiler/para081.png");
				background-size: 100% 100%;
				margin-top: 2vh;

				.shadow1 {
					width: 8vw;
					height: 12vh;
					margin-top: 15vh;
					margin-left: 0.8vw;

					.shadow_words {
						font-size: 2vh;
						line-height: 3vh;
						color: #2fc3e3;
						text-align: center;
					}
				}

				.shadow2 {
					width: 7vw;
					height: 2.6vh;
				}

				.float2 {
					margin-top: 17vh;
					margin-left: 0.1vw;
				}

				.float3 {
					margin-left: -0.7vw;
					margin-top: 4.4vh;
				}

				.float4 {
					margin-left: 0.6vw;
					margin-top: 4vh;
				}

				.shadow3 {
					width: 7vw;
					height: 8vh;
					margin-top: 0.5vh;
					margin-left: 0.6vw;

					.shadow_words {
						font-size: 2vh;
						line-height: 3vh;
						color: #2fc3e3;
						text-align: center;
					}
				}

				.pz {
					font-size: 1vh;
					line-height: 3vh;
					color: #2fc3e3;

					.pz1 {
						margin-top: 0.2vh;
						margin-left: 1.6vw;
					}

					.pz2 {
						margin-top: 4.8vh;
						margin-left: 1.6vw;
					}

					.pz3 {
						margin-top: 4.2vh;
						margin-left: 1.6vw;
					}

					.pz4 {
						margin-top: 4.6vh;
						margin-left: 1.6vw;
					}
				}

				.float6 {
					margin-top: 11.6vh;
					margin-left: 2.5vw;
				}
			}

			.three {
				.shadow1 {
					width: 7vw;
					height: 7vh;
				}

				.float1 {
					margin-top: 0vh;
					margin-left: 0vw;
				}

				.float2 {
					margin-top: 0.3vh;
					margin-left: 0vw;
				}

				.float3 {
					margin-top: 0.3vh;
					margin-left: 0vw;
				}

				.float4 {
					margin-top: 0.3vh;
					margin-left: 0vw;
				}
			}
		}
	}
</style>
